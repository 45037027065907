@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500&display=swap');

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol","Noto Sans KR", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Noto Sans KR", sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

body {
  font-family: "Noto Sans KR", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans KR", sans-serif; */
  /* font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff; */
  height: 100%;
}

/* .app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

input.error {
  border-color: red;
}

.input-feedback {
  color: "#c0392b";
  height: 5px;
  /* margin-top: -12px; */
}

.button-airbnb {
  background-color: "#01848A";
  color: "white";
  border: "none";
  font-size: "16px";
  font-weight: "700";
}

input:focus{
  outline:none;
}